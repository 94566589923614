import React from "react"
import { graphql } from "gatsby"
import LegalLayout from "../components/layout/legalLayout"

import JobLayout from "../components/layout/JobLayout"
import NewsLayout from "../components/layout/NewsLayout"

export default function Template(props) {
  const { markdownRemark } = props.data // data.markdownRemark holds your post data
  console.log({ props })
  if (markdownRemark?.frontmatter?.readingTime) {
    const { slug, category, date, image, readingTime, summary, title } =
      markdownRemark.frontmatter
    return (
      <NewsLayout
        slug={slug}
        category={category}
        date={date}
        image={image}
        readingTime={readingTime}
        summary={summary}
        title={title}
      >
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
        />
      </NewsLayout>
    )
  }

  if (!markdownRemark?.frontmatter?.employment) {
    return (
      <LegalLayout
        title={markdownRemark?.frontmatter?.title ?? "Page not found"}
        header={markdownRemark?.frontmatter?.title ?? "Page not found"}
      >
        {markdownRemark && markdownRemark.html && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
          />
        )}
      </LegalLayout>
    )
  }
  const {
    employment,
    location,
    salary,
    startdate,
    title,
    language,
    techstack,
    apply,
  } = markdownRemark.frontmatter

  const pageTitle = title

  return (
    <JobLayout
      title={pageTitle}
      header={pageTitle}
      employment={employment}
      location={location}
      salary={salary}
      startdate={startdate}
      language={language}
      techstack={techstack}
      apply={apply}
    >
      {markdownRemark && markdownRemark.html && (
        <div
          className="jobs"
          dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
        />
      )}
    </JobLayout>
  )
}

export const pageQuery = graphql`
  query ($frontmatter__slug: String, $language: String!) {
    markdownRemark(
      frontmatter: {
        slug: { eq: $frontmatter__slug }
        language: { eq: $language }
      }
    ) {
      html
      frontmatter {
        slug
        title
        employment
        location
        salary
        startdate
        language
        techstack
        apply
        category
        date
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        readingTime
        summary
        title
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `
