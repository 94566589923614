import React from "react"
import SEO from "../seo"
import BaseLayout from "./baseLayout"
import {
  BriefcaseIcon,
  CalendarIcon,
  ChevronRightIcon,
  CubeTransparentIcon,
  CurrencyEuroIcon,
  ExternalLinkIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid"
import "./jobs.css"
import { Trans } from "react-i18next"
import { Link } from "gatsby"
import BackgroundSquare from "../common/BackgroundSquare"

interface IJob {
  children?: React.ReactNode
  employment?: string
  location?: string
  salary?: string
  startdate?: string
  title?: string
  language?: string
  techstack?: string
  apply?: string
}

const JobLayout = (props: IJob) => {
  const {
    children,
    employment,
    location,
    salary,
    startdate,
    title,
    language,
    techstack,
    apply,
  } = props
  const ApplyButton = () => (
    <a
      href={apply}
      className="h-10 btn-solid whitespace-nowrap text-sm md:text-base"
    >
      <span className="mx-8">
        {language == "de" ? "Jetzt bewerben" : "Apply"}
      </span>
    </a>
  )
  return (
    <div className="">
      <SEO title={title} isHome={false} />
      <BaseLayout>
        <div className="base-container pt-8 md:pt-32 pb-0">
          <div className="flex-1 min-w-0 pb-2 mb-8 border-b-1 border-gray-300">
            <div className="flex flex-row justify-between">
              <div className="mr-2">
                <div className="relative z-[-1]">
                  <BackgroundSquare />
                </div>
                <nav className="flex" aria-label="Breadcrumb">
                  <ol
                    role="list"
                    className="flex items-center space-x-2 md:space-x-4"
                  >
                    <li>
                      <div>
                        <Link
                          to="/jobs"
                          className="text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          <Trans>Jobs</Trans>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-4 text-sm font-medium text-gray-500">
                          {title.includes("(m") ? title.split(" (m")[0] : title}
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>
                <h1 className="mt-2 mb-1 text-2xl font-extrabold leading-7 text-slate-800 sm:text-3xl whitespace-pre-wrap">
                  {title}
                </h1>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <BriefcaseIcon
                      className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {employment}
                  </div>
                  {location && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <LocationMarkerIcon
                        className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {location}
                    </div>
                  )}
                  {salary && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <CurrencyEuroIcon
                        className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {salary}
                    </div>
                  )}
                  {startdate && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <CalendarIcon
                        className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {startdate}
                    </div>
                  )}
                  {techstack && (
                    <div className="group mt-2 flex items-center text-sm text-gray-500">
                      <CubeTransparentIcon
                        className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <a
                        href={techstack}
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="text-primary-300 group-hover:text-primary cursor-pointer font-medium"
                      >
                        <span>Tech Stack</span>
                        <ExternalLinkIcon
                          className="inline relative ml-1.5 -mt-1 h-4 w-4 text-primary-200 group-hover:text-primary-3 00"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6">
              <ApplyButton />
            </div>
          </div>
          {children}
          <div className="mt-10 mb-12">
            <ApplyButton />
          </div>
        </div>
      </BaseLayout>
    </div>
  )
}

export default JobLayout
