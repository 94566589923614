import React from "react"
import SEO from "../seo"
import BaseLayout from "./baseLayout"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image"

interface INews {
  category: string
  title: string
  summary: string
  author: string
  image: any
  authorImage: any
  date: Date | string | number
  readingTime: string
  slug: string
  children: React.ReactNode
}

const NewsLayout = ({
  // author,
  category,
  date,
  image,
  readingTime,
  summary,
  title,
  // slug,
  // authorImage,
  children,
}: INews) => {
  const { i18n } = useTranslation()

  return (
    <div className="bg-slate-50">
      <SEO title={title} isHome={false} />
      <BaseLayout>
        <div className="mx-auto max-w-5xl px-6 pt-16 pb-0">
          <div className="md:text-center">
            <div className="uppercase text-primary font-bold text-xl lg:text-2xl mb-2 lg:mb-4">
              {category}
            </div>
            <h1 className="mb-8 md:px-16">{title}</h1>
            <div className="md:px-32">{summary}</div>
            <div className="text-primary my-8">
              {new Date(date).toLocaleDateString(i18n.language)} |{" "}
              {i18n.language === "de" ? "Lesezeit " : ""}
              {readingTime} {i18n.language === "en" ? "read" : ""}
            </div>
            <Img
              className="w-full mx-auto aspect-blog rounded-xl mb-8"
              fluid={image.childImageSharp.fluid}
            />
          </div>
          <div className="prose prose-h3:font-bold max-w-none prose-slate">
            {children}
          </div>
        </div>
      </BaseLayout>
    </div>
  )
}

export default NewsLayout
