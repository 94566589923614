import React from "react"
import SEO from "../seo"
import BaseLayout from "./baseLayout"
import "./layout.css"
import "./frontmatter.css"
import BackgroundSquare from "../common/BackgroundSquare"

const LegalLayout = ({ children, title, header }) => (
  <div className="">
    <SEO title={title} isHome={false} />
    <BaseLayout>
      <div className="base-container mt-24 text-secondary">
        <div className="relative z-[-1]">
          <BackgroundSquare />
        </div>
        <h1 className="text-4xl mb-4 font-extrabold">{header}</h1>

        {children}
      </div>
    </BaseLayout>
  </div>
)

export default LegalLayout
